<template>
    <div class="platon-call24">
        <h4 class="platon-call24__title">
            {{ $l("platon.call24_title", "Call24 виджетининг саҳифа созламалари") }}
        </h4>
        <div
            class="platon-call24__alert"
            v-if="!hasCall24Widget"
            v-html="
                $l(
                    'platon.call24_alerttxt',
                    'Call24 ушбу лойиҳада мавжуд эмас. Call24ни <a href=https://doc.platon.uz/uz/home>ушбу хавола</a> орқали қандай лойиҳага ўрнатиш ҳақида танишиб чиқишингиз мумкин.'
                )
            "
        ></div>
        <page-loading-view v-show="isLoading" />
        <div class="platon-call24__body" v-show="!isLoading" v-if="hasCall24Widget">
            <div class="platon-call24__tabs">
                <b-tabs>
                    <b-tab class="platon-call24__tab" title="Private routes" active>
                        <b-form
                            class="platon-call24__form platon-c24form pl-private"
                            @submit.prevent="addRow('private')"
                        >
                            <div class="platon-c24form__infobar">
                                <h2 class="platon-c24form__infobar-title">
                                    {{ $l("platon.call24_private_routes", "Private routes") }}
                                </h2>
                                <div class="platon-c24form__infobar-switch custom-switch b-custom-control-lg">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="private"
                                        v-model="private.all"
                                    />
                                    <label class="custom-control-label" for="private">
                                        <p
                                            class="platon-c24form__infobar-label"
                                            v-html="
                                                $l(
                                                    'platon.call24_private_routes_all',
                                                    'Барча <strong>private route</strong>лар учун йоқиш'
                                                )
                                            "
                                        ></p>
                                    </label>
                                </div>
                                <btn
                                    class="platon-c24form__infobar-btn"
                                    type="submit"
                                    variant="secondary"
                                    :disabled="isLastPrivateRowEmpty || private.all"
                                >
                                    {{ $l("platon.call24_add_route", "Route қўшиш") }}
                                    <!-- <i class="fas fa-plus" /> -->
                                </btn>
                            </div>
                            <div class="platon-c24form__list" :class="{ 'platon-c24form__list-disabled': private.all }">
                                <div class="platon-c24form__row" v-for="(page, index) of private.pages" :key="index">
                                    <div class="platon-c24form__row-checkbox custom-switch b-custom-control-lg">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="`private-${page.id}-${index}`"
                                            v-model="page.state"
                                        />
                                        <label class="custom-control-label" :for="`private-${page.id}-${index}`">
                                            {{ $l("platon.call24_route_state", "State") }}
                                        </label>
                                    </div>
                                    <div class="platon-c24form__row-input">
                                        <TextControl
                                            class="mb-0"
                                            :value="page.path"
                                            @input="(e) => handleChange(e, index, 'private')"
                                            label=""
                                            :placeholder="$l('platon.call24_route_name', 'Йўл номи')"
                                        />
                                    </div>
                                    <btn
                                        class="platon-c24form__row-btn"
                                        @click.native="removeRow(index, 'private')"
                                        variant="danger"
                                        :disabled="private.pages.length == 1 && page.path == ''"
                                    >
                                        <i class="mdi mdi-delete-forever" />
                                    </btn>
                                </div>
                            </div>
                        </b-form>
                    </b-tab>
                    <b-tab class="platon-call24__tab" title="Public routes">
                        <b-form class="platon-call24__form platon-c24form pl-public" @submit.prevent="addRow('public')">
                            <div class="platon-c24form__infobar">
                                <h2 class="platon-c24form__infobar-title">
                                    {{ $l("platon.call24_public_routes", "Public routes") }}
                                </h2>
                                <div class="platon-c24form__infobar-switch custom-switch b-custom-control-lg">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="public"
                                        v-model="public.all"
                                    />
                                    <label class="custom-control-label" for="public">
                                        <p
                                            class="platon-c24form__infobar-label"
                                            v-html="
                                                $l(
                                                    'platon.call24_public_routes_all',
                                                    'Барча <strong>public route</strong>лар учун йоқиш'
                                                )
                                            "
                                        ></p>
                                    </label>
                                </div>
                                <btn
                                    class="platon-c24form__infobar-btn"
                                    type="submit"
                                    variant="secondary"
                                    :disabled="isLastPublicRowEmpty || public.all"
                                >
                                    {{ $l("platon.call24_add_route", "Route қўшиш") }}
                                    <!-- <i class="fas fa-plus" /> -->
                                </btn>
                            </div>
                            <div class="platon-c24form__list" :class="{ 'platon-c24form__list-disabled': public.all }">
                                <div class="platon-c24form__row" v-for="(page, index) of public.pages" :key="page.id">
                                    <div class="platon-c24form__row-checkbox custom-switch b-custom-control-lg">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="`public-${page.id}-${index}`"
                                            v-model="page.state"
                                        />
                                        <label class="custom-control-label" :for="`public-${page.id}-${index}`">
                                            {{ $l("platon.call24_route_state", "State") }}
                                        </label>
                                    </div>
                                    <div class="platon-c24form__row-input pl-public">
                                        <span class="platon-c24form__row-input-prefix">/public/</span>
                                        <TextControl
                                            class="mb-0"
                                            :value="page.path"
                                            @input="(e) => handleChange(e, index, 'public')"
                                            label=""
                                            autocomplete="path"
                                        />
                                    </div>
                                    <btn
                                        class="platon-c24form__row-btn"
                                        @click.native="removeRow(index, 'public')"
                                        variant="danger"
                                        :disabled="public.pages.length == 1 && page.path == ''"
                                    >
                                        <i class="mdi mdi-delete-forever" />
                                    </btn>
                                </div>
                            </div>
                        </b-form>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="platon-call24__actions">
                <div class="platon-call24__actions-formain">
                    <div class="platon-call24__actions-formain-switch">
                        <div class="custom-switch b-custom-control-lg">
                            <input type="checkbox" class="custom-control-input" id="main" v-model="main" />
                            <label class="custom-control-label" for="main">
                                <p class="platon-call24__actions-formain-label">
                                    {{ $l("platon.call24_formain", "State | Асосий саҳифа") }}
                                </p>
                            </label>
                        </div>
                    </div>
                </div>

                <btn class="platon-c24form__row-btn mr-2" variant="secondary" @click.native="cancelConfig">
                    {{ $l("platon.form_restore", "Бекор қилиш") }}
                </btn>
                <btn class="platon-c24form__row-btn mr-2" variant="primary" @click.native="saveConfig">
                    {{ $l("platon.save", "Сақлаш") }}
                </btn>
            </div>
        </div>
    </div>
</template>
<script>
import { minLength, required } from "vuelidate/lib/validators"
import ToastMixin from "@Platon/mixins/ToastMixin"

import Btn from "@Platon/components/extended/Btn.vue"
import TextControl from "@Platon/components/form/controls/TextControl.vue"
import PageLoadingView from "@Platon/components/pages/PageLoadingView"
import { API_BASE_URL } from "@Platon/const"

export default {
    components: { Btn, TextControl, PageLoadingView },
    mixins: [ToastMixin],

    data() {
        return {
            isLoading: false,
            main: false,
            private: {
                all: true,
                pages: [
                    {
                        id: 1,
                        path: "",
                        state: false
                    }
                ]
            },
            public: {
                all: true,
                pages: [
                    {
                        id: 1,
                        path: "",
                        state: false
                    }
                ]
            },
            backup: {},
            hasCall24: true
        }
    },

    validations() {
        return {
            "private.pages": {
                $each: {
                    path: {
                        required,
                        minLength: minLength(6)
                    }
                }
            },
            "public.pages": {
                $each: {
                    path: {
                        required
                    }
                }
            }
        }
    },
    mounted() {
        this.getConfigFile()
    },

    computed: {
        hasCall24Widget() {
            return !!window.Call24
        },
        isLastPrivateRowEmpty() {
            return this.private.pages[this.private.pages.length - 1].path == ""
        },
        isLastPublicRowEmpty() {
            return this.public.pages[this.public.pages.length - 1].path == ""
        }
    },

    methods: {
        handleChange(val, index, type) {
            let regex = /[?.=&@]/g
            let transformed = val.replace(regex, " ")

            this[type].pages[index].path = transformed
        },
        resetData(data) {
            this.main = data.main
            this.public = data.public
            this.private = data.private

            if (data.private.pages.length == 0) {
                this.addRow("private")
            }
            if (data.public.pages.length == 0) {
                this.addRow("public")
            }

            this.backup = JSON.parse(JSON.stringify(data))
        },
        async getConfigFile() {
            this.isLoading = true
            try {
                const { data } = await this.$api({
                    baseURL: API_BASE_URL,
                    url: "/call24/",
                    method: "GET"
                })

                if (data.hasOwnProperty("hasCall24")) {
                    this.hasCall24 = data.hasCall24
                } else {
                    data.public.pages.forEach((element) => {
                        element.path = element.path.replace("/public/", "")
                    })
                    data.private.pages.forEach((element) => {
                        if (!element.path.startsWith("/")) {
                            element.path = "/" + element.path
                        }
                    })
                    this.resetData(data)
                }
            } catch (error) {
                new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        async saveConfig() {
            this.isLoading = true
            let publicPages = this.public
            let privatePages = this.private
            let requestType = this.hasCall24 ? "update" : "create"
            let requestMethod = this.hasCall24 ? "PUT" : "POST"

            publicPages.pages.forEach((element) => {
                element.path = "/public/" + element.path
            })

            privatePages.pages.forEach((element) => {
                if (!element.path.startsWith("/")) {
                    element.path = "/" + element.path
                }
            })

            if (this.isLastPublicRowEmpty) {
                publicPages.pages.pop()
            }
            if (this.isLastPrivateRowEmpty) {
                privatePages.pages.pop()
            }

            try {
                await this.$api({
                    baseURL: API_BASE_URL,
                    url: `/call24/${requestType}`,
                    method: requestMethod,
                    data: {
                        main: this.main,
                        private: privatePages,
                        public: publicPages
                    }
                })
                await this.getConfigFile()
                this.greenToast(this.$l("platon.call24_saved", "Call24 config saved"))
            } catch (error) {
                new Error(error)
            } finally {
                this.isLoading = false
            }
        },
        addRow(type) {
            let newRow = {
                path: "",
                state: false,
                id: this[type].pages.length + 1
            }

            this[type].pages.push(newRow)
        },
        removeRow(index, type) {
            const len = this[type].pages.length
            if (len != 1) {
                this[type].pages.splice(index, 1)
            } else if (len == 1 && this[type].pages[0].path !== "") {
                this.addRow(type)
                this[type].pages.splice(index, 1)
            }
        },
        cancelConfig() {
            let { main, private: privateData, public: publicData } = JSON.parse(JSON.stringify(this.backup))
            this.main = main
            this.private = privateData
            this.public = publicData
        }
    }
}
</script>

<style lang="scss">
@import "./_style.scss";
</style>
